import { navigate } from 'gatsby';

export const onRouteUpdate = ({ location }: { location: Location }) => {
  if (location.pathname != '/' && location.pathname.length > 1) {
    const pathname = location.pathname;

    const channel = pathname.split('/')[1];

    if (channel) {
      // Write the 'data' value to session storage
      sessionStorage.setItem('channel', channel);
      console.log('Channel:' + channel);
    }

    navigate('/');
  }
}